.content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; // Enable scrolling for the container
    max-height: 100vh; // Limit the height to the viewport height
    height: auto;
    z-index: 1;


    .headline {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        max-width: $fullwidth;
        width: 100%;
        margin-top: calc(#{$headerheight} - #{$spaceTwo});
        margin-left: auto;
        margin-right: auto;
        min-height: 294px;

        img {
            width: 100%;
        }
    }


    .athletes-wrapper {
        flex-grow: 1; // Allow this container to take up available space
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100vw;
        height: auto;


        .discipline-row {
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 100vw;
            position: relative;

            .arrowleft {
                width: 16px;
                cursor: pointer;
                position: absolute;
                top: 50%;
                left: $spaceOne; // Make sure this variable is defined elsewhere in your SCSS
                z-index: 2001;
                transform: translateY(-50%); // Adjust for vertical centering
                display: flex; // Use flexbox to center the SVG
                justify-content: center; // Center horizontally in the flex container
                align-items: center; // Center vertically in the flex container
                border-radius: 100px;
                padding: 10px 12px 10px 10px;
                @include blur-background-1(5px);
                border: 1px solid $white;
                svg {
                    
                    path {
                        
                        stroke:  white;
                    }
                }
            }

            .arrowright {
                width: 16px;
                cursor: pointer;
                position: absolute;
                top: 50%;
                right: $spaceOne; // Make sure this variable is defined elsewhere in your SCSS
                z-index: 2001;
                transform: translateY(-50%); // Adjust for vertical centering
                display: flex; // Use flexbox to center the SVG
                justify-content: center; // Center horizontally in the flex container
                align-items: center; // Center vertically in the flex container
                border-radius: 100px;
                padding: 10px 10px 10px 12px;
                @include blur-background-1(5px);
                border: 1px solid $white;
                svg {
                    
                    path {
                        stroke: $white;
                    }
                }
            }

            .discipline-headline {
                @include headline();
            }

            .athletes-row {
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                overflow-x: auto;
                flex-wrap: nowrap;
                align-items: stretch;
                max-height: 31vw;
                cursor: auto;
                user-select: none;


                /* Hide scrollbar for IE, Edge, and Firefox */
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */

                /* Hide scrollbar for Chrome, Safari and Opera */
                &::-webkit-scrollbar {
                    display: none;
                }

                &:active {
                    cursor: grabbing;
                }

                
            }

            .athlete-card {
                position: relative;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                width: 24vw;
                min-width: 24vw;
                height: 31vw;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: grayscale(100%);
                    transition: filter 0.5s ease;
                }

                &:hover img {
                    filter: grayscale(0%);
                }

                &:hover .athlete-overlay {
                    display: flex;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 24vw;
                    max-width: 24vw;
                    height: 10%;
                    flex-direction: column;
                    justify-content: flex-start;
                    background-color: $blue;
                    color: $white;
                    display: flex;
                    padding: 10px 10px;
                    align-items: flex-start;
                    box-sizing: border-box;
                }

                .athlete-overlay {
                    display: none;
                }
            }
        }
    }
}


@include respond-to('medium') {
    .content {

        // background-color: red;
        .headline {
            img {
                width: 70%;
            }
        }

        .athletes-wrapper {

            .discipline-row {


                .athletes-row {
                    max-height: 43vw;
                }

                .athlete-card {
                    width: 34vw;
                    min-width: 34vw;
                    height: 43vw;


                    &:hover .athlete-overlay {
                        bottom: 0;
                        left: 0;
                        width: 34vw;
                        min-width: 34vw;
                        height: 10%;
                        padding: 10px 10px;
                    }

                    .athlete-overlay {
                        display: none;
                    }
                }
            }
        }
    }
}



@include respond-to('phone') {
    .content {
        padding-bottom: env(safe-area-inset-bottom);

        // background-color: green;
        .headline {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            max-width: $fullwidth;
            width: 100%;
            margin-top: calc(#{$headerheight} - #{$spaceTwo});
            margin-left: auto;
            margin-right: auto;
            min-height: 294px;

            img {
                width: $fullwidth;
            }

        }

        .athletes-wrapper {

            .discipline-row {
                padding-bottom: 34px;

            .arrowleft {
                top: calc(50% - 14px);
            }

            .arrowright {

                top: calc(50% - 14px);
            }


                .athletes-row {
                    max-height: 100vw;
                }

                .athlete-card {
                    width: 80vw;
                    min-width: 80vw;
                    height: 100vw;


                    &:hover .athlete-overlay {
                        bottom: 0;
                        left: 0;
                        width: 80vw;
                        min-width: 80vw;
                        height: 10%;
                        padding: 10px 10px;
                    }

                    .athlete-overlay {
                        display: none;
                    }
                }
            }
        }
    }
}