@import 'variables';
@import 'mixins';
@import 'header';
@import 'main';
@import 'athletes';
@import 'footer';
@import 'filter';
@import 'overlay';
@import 'AchievementsPage.module';
@import 'NewsPage.module';
@import 'AboutPage.module';



body {
    @include apply-typography-styles(map-get($typography-styles, default));
    

    h1 {
        @include apply-typography-styles(map-get($typography-styles, hl1))
    }

    h2 {
        @include apply-typography-styles(map-get($typography-styles, hl2))
    }

    h3 {
        @include apply-typography-styles(map-get($typography-styles, hl3))
    }

    h4 {
        @include apply-typography-styles(map-get($typography-styles, hl4))
    }

    a {
        cursor: pointer;
    }

    .note {
        @include apply-typography-styles(map-get($typography-styles, note))
    }

    .App {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $whiteTerciary;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        -webkit-tap-highlight-color: transparent;
    }

}

@include respond-to('phone') {
    body {
        @include apply-typography-styles(map-get($typography-styles, mobile));

        .App {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $whiteSecondary;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
    
        
        }
    }
}