

.filters {
    position: sticky;
    top: $headerheight; // Adjust this value based on your header or navbar height if needed
    z-index: 2; // Ensure it's above other content
    width: 100%; // Ensure it spans the full width

    .filter-select {
        @include filters();
    }
    .filter-options {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 8px;
      @include blur-background-2(5px);
      z-index: 10;

      .filter-option {
        @include buttonFilter();
      }
    }
  }