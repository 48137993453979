.main {
    width: 100%;
    height: 100vh;
    margin-top: $headerheightmain; //height of the header
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
    z-index: 2001;

    .logo-wrapper {
        flex-basis: 0;
        width: 100%;
        margin: 0;
        // margin-top: -5px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        .lottie {
            width: $fullwidth;
        }

        .lottie-mobile {
            display: none;
        }
    }

    @include respond-to('phone') {
        .logo-wrapper {
            flex-basis: 0;
            width: 100%;
            margin: 0;
            margin-top: $spaceOne;
            margin-bottom: $spaceOne;
            display: flex;
            justify-content: center;
            align-items: center;

            .lottie {
                display: none;
            }

            .lottie-mobile {
                display: block;
                width: $fullwidth;
            }

        }
    }

    .images-row {
        flex-grow: 3; // fills remaining space
        display: flex;
        overflow-x: auto;
        transition: width 1.5s ease;
        margin: 0;

        .image-container {
            min-width: 130px;
            max-width: 130px;
            overflow: hidden;
            margin: 0;
            transition: min-width 1.5s ease-in-out;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(100%);
                transition: filter 0.5s ease;
            }

            &:hover img {
                filter: grayscale(0%);
            }

            &:hover {
                min-width: 200px;
                max-width: 200px;
            }
        }
    }

    .olyrings {
        width: 85px;
        position: fixed;
        bottom: 22px;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
    }
}