footer {
    @include blur-background(10px);
    @include apply-typography-styles(map-get($typography-styles, note));
    bottom: 0px; 
    min-height: 5px;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    .footer-content {
      width: $fullwidth;
      white-space: nowrap; 
      // justify-content: space-between; 
      display: flex;
      flex-direction: row;
      justify-content: left;
      p {
        padding-right: 12px;
      }
    }
  }

  .fixed-footer {
    @include blur-background(10px);
    @include apply-typography-styles(map-get($typography-styles, note));
    // background-color: $whiteSecondary;
    position: fixed; 
    bottom: 0px; 
    min-height: 32px;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    
    .footer-content {
      width: $fullwidth;
      white-space: nowrap; // Prevent content from wrapping
      display: flex;
      flex-direction: row;
      justify-content: left;
    }

   
  }

  .overlay {
    position: fixed; 
    bottom: 0px; 
    top: 0;
    left: 0;
    box-sizing: border-box;
    min-width: $fullwidth;
    // height: 100%;
    @include blur-background(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000; /* Ensure overlay is above other content */
    overflow:auto;
  }
  
  .overlay-content {
    position: relative;
    padding: 250px 20px 0 20px;
    width: 100%;
    height: 100%;
    max-width: 100vw; /* Adjust as needed */
    overflow-y: auto; /* Allows scrolling if the content is too long */
    p {
      width: 80%;
    }
  }
  
  .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    cursor: pointer;
    @include apply-typography-styles(map-get($typography-styles, default));
  }
  