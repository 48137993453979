.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .headline {
        text-align: center;
        flex-direction: column;
        align-items: center;
        width: 48vw;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .container-columns {
        width: 100vw;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: 100%;

        .aboutDesktop {
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            flex-wrap: wrap;
            @include apply-typography-styles(map-get($typography-styles, serif));
            hyphens: auto;
            text-align: justify;
            background-color: $black20;
        }

        .aboutItem {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            overflow: hidden;
            width: calc(100vw / 3);
            // flex-grow: 1;
            box-sizing: border-box;
            // height: 100%;
            /* Allow it to fill the parent height */

            .imgWrapper {
                width: calc(100vw / 3);
                min-height: 41vw;
                height: 41vw;

                .aboutImg {
                    width: 100%; // Image will take up the entire width of its container
                    height: 100%; // Maintain aspect ratio
                    object-fit: cover;
                    filter: grayscale(100%);
                    transition: filter 0.5s ease;
                }

                &:hover .aboutImg {
                    filter: grayscale(0%);
                }
            }
        }

        .descriptionContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            padding: 52px;
            box-sizing: border-box;
            flex-grow: 1;

            @media (max-width: 768px) {
                padding: 26px; // Reduce padding for smaller screens
            }

                .topContainer,
                .bottomContainer {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
            // flex-grow: 1; /* Allow the containers to grow */

                p,
                h3 {
                    padding: 0;
                    margin: 0;
                }
            }

            .topContainer {
                gap: 4px;
                padding-bottom: 52px;
            }

            .bottomContainer {
                gap: 20px;
                display: flex;
                justify-content: space-between;
                height: 100%;
            }

            .button {
                @include terciaryButton(); // Assuming you have a mixin defined for this
                width: 100%;
            }
        }
    }
}




@include respond-to('phone') {

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .headline {
            text-align: center;
            flex-direction: column;
            align-items: center;
            width: 85vw;
        }

        .headline img {
            max-width: 100%;
            height: auto;
        }

        .container-columns {
            width: 100vw;
            display: flex;
            flex-direction: row;


            .aboutDesktop {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                @include apply-typography-styles(map-get($typography-styles, serif));
                hyphens: auto;
                text-align: justify;
                background-color: $black20;
            }

            .aboutItem {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                overflow: hidden;
                width: 100vw;
                height: auto;

                .imgWrapper {
                    width: 100vw;
                    min-height: 145vw;
                    height: 145vw;
                    // Adjust the aspect ratio based on your design requirements.

                    .aboutImg {
                        width: 100%; // Image will take up the entire width of its container
                        height: 100%; // Maintain aspect ratio
                        object-fit: cover;
                        filter: grayscale(100%);
                        transition: filter 0.5s ease;
                    }

                    &:hover img {
                        filter: grayscale(0%);
                    }
                }
            }

            .descriptionContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: left;
                padding: 52px; // Default padding
                box-sizing: border-box;

                @media (max-width: 768px) {
                    padding: 26px; // Reduce padding for smaller screens
                }

                .topContainer,
                .bottomContainer {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    margin: 0;

                    p,
                    h3 {
                        padding: 0;
                        margin: 0;
                    }
                }

                .topContainer {
                    gap: 4px;
                    padding-bottom: 52px;
                }

                .bottomContainer {
                    gap: 20px;
                    box-sizing: border-box;
                }

                .button {
                    @include terciaryButton(); // Assuming you have a mixin defined for this
                    width: 100%;
                }
            }
        }
    }

}