.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .toggleButtonMobile,
    .eventsMobile,
    .newsMobile {
        display: none;
    }


    .headline img {
        max-width: 100%;
        height: auto;
    }


    .toprowDesktop {
        top: $headerheight;
        // padding-top: $headerheight;
        position: sticky;
        width: 100vw;
        display: flex;
        flex-direction: row;
        @include headline();
        z-index: 2001;

        .newsToprow,
        .eventsToprow {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .newsToprow {
            flex: 2;
        }

        .eventsToprow {
            flex: 1;
        }

    }

    .container {
        min-width: 100vw;
    }

    .container-columns-mobile {
        display: none;
    }

    .container-columns {
        width: 100vw;
        display: flex;
        flex-direction: row;



        .newsDesktop,
        .eventsDesktop {
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            @include apply-typography-styles(map-get($typography-styles, serif));
            hyphens: auto;
            text-align: justify;

            .topContainer,
            .bottomContainer {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                margin: 0;

                p,
                h3 {
                    padding: 0;
                    margin: 0;
                }
            }

            .topContainer {
                gap: 4px;
                padding-bottom: 52px;
            }

            .bottomContainer {
                gap: 20px;
                box-sizing: border-box;
            }

            .button {
                @include terciaryButton();
            }
        }

        .newsDesktop {
            // max-width: 66vw;
            background-color: $black20;
            flex: 2; // Takes up 2/3 of the space

            .button {
                width: 50%;
            }
        }

        .eventsDesktop {
            // max-width: 33vw;
            background-color: $white;
            flex: 1; // Takes up 1/3 of the space

            .buttonRow {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: stretch;
                gap: 8px;

                .button {
                    min-width: 50%;
                    flex-grow: 1;
                }
            }
        }

        .newsItem {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            width: 100%;
            // min-height: 400px;
            overflow: hidden;

            .newsImg {
                width: 50%; // Take up the entire width of its container
                height: auto; // Maintain aspect ratio
                object-fit: cover; // Cover the area of the container, possibly cropping the image
                min-width: 50%; // Ensure it takes up at least half the container width
                min-height: 100%; // Ensure it covers the full height
            }

            .descriptionContainer {
                flex: 1; // Fill the remaining space
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: left;
                padding: 52px;
                box-sizing: border-box;
            }
        }

        .eventsItem {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 52px 52px 0px 52px;

            &:last-child {
                padding: 52px;
            }

            .descriptionContainer {
                text-align: left;

            }
        }
    }
}


@include respond-to('phone') {

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .toprowDesktop,
        .container-columns,
        .newsDesktop,
        .eventsDesktop {
            display: none;
        }

        .container-columns {
            display: none;
    
    
            .newsDesktop,
            .eventsDesktop {
                display: none;
            }
        }


        .headline img {
            max-width: 100%;
            height: auto;
        }

        .container {
            min-width: 100vw;
        }

        .toggleButtonMobile {
            display: flex;
            justify-content: center;
            position: sticky;
            top: $headerheight; // Adjust this value based on your header or navbar height if needed
            z-index: 2; // Ensure it's above other content
            width: 100%; // Ensure it spans the full width
            height: 100%;
            cursor: pointer;

            .activeButtonStyle,
            .inactiveButtonStyle {
                @include blur-background-2(10px);
                @include apply-typography-styles(map-get($typography-styles, default));
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 25px;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
            }

            .activeButtonStyle {
                background-color: $blue;
                color: $white;
            }

            .inactiveButtonStyle {
                background-color: none;
                color: $primaryBlack;

                &:hover {
                    background-color: $primaryGrey;
                    color: $white;
                }
            }

        }


        .container-columns-mobile {
            width: 100vw;
            display: flex;
            flex-direction: row;


            .newsMobile,
            .eventsMobile {
                height: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                @include apply-typography-styles(map-get($typography-styles, serif));
                hyphens: auto;
                text-align: justify;

                .topContainer,
                .bottomContainer {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    margin: 0;

                    p,
                    h3 {
                        padding: 0;
                        margin: 0;
                    }
                }

                .topContainer {
                    gap: 4px;
                    padding-bottom: 52px;
                }

                .bottomContainer {
                    gap: 20px;
                    box-sizing: border-box;
                }

                .button {
                    @include terciaryButton();
                }
            }

            .newsMobile {
                // max-width: 66vw;
                background-color: $black20;
                flex: 1;

                .button {
                    width: 50%;
                }
            }

            .eventsMobile {
                // max-width: 33vw;
                background-color: $black20;
                flex: 1;

                .buttonRow {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: stretch;
                    gap: 8px;

                    .button {
                        min-width: 50%;
                        flex-grow: 1;
                    }
                }
            }

            .newsItem {
                display: flex;
                flex-direction: column;
                align-items: stretch;
                width: 100%;
                // min-height: 400px;
                overflow: hidden;

                .newsImg {
                    width: 100%; // Take up the entire width of its container
                    height: auto; // Maintain aspect ratio
                    object-fit: cover; // Cover the area of the container, possibly cropping the image
                    min-width: 100%; // Ensure it takes up at least half the container width
                    min-height: 100%; // Ensure it covers the full height
                }

                .descriptionContainer {
                    flex: 1; // Fill the remaining space
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    text-align: left;
                    padding: 30px 20px 40px 20px;
                    box-sizing: border-box;
                }
            }

            .eventsItem {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 30px 20px 40px 20px;

                &:last-child {
                    padding: 30px 20px 40px 20px;
                }

                .descriptionContainer {
                    text-align: left;

                }
            }
        }
    }

}