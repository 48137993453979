/* colours */
$blue: #3400C7;
$primaryBlack: #131313;
$black10-70: rgba(240, 240, 240, 0.70);
$primaryGrey: #4C4C4C;
$black50: #868686;
$black20: #f7f7f7;
$white: #ffffff;
$whiteSecondary: #f8f8f8;
$whiteTerciary: rgba(245, 245, 245, 0.8);
$whiteQuarternary: rgba(245, 245, 245, 0.1);

$border: 0.5px solid $primaryBlack;

/* sizes */
$spaceOne: 24px;
$spaceTwo: 28px;

$fullwidth: calc(100vw - #{$spaceOne * 2});
$headerheightmain: calc(96px - #{$spaceOne * 2});
$headerheightscroll: calc(87px - #{$spaceOne * 2});
$headerheight: 87px; //height of the header
$contentheight: calc(100vh - #{$headerheight});

$font: "ABC Diatype", Helvetica, Arial, sans-serif;
$fontSerif: "Times New Roman", serif;


// Typographic styles map
$typography-styles: (
    default: (font-family: $font,
        font-size: 11px,
        font-style: normal,
        font-weight: 400,
        text-align: left,
        line-height: normal,
        letter-spacing: 0.55px,
        text-transform: uppercase),

    note: (font-family: $font,
        color: $primaryGrey,
        font-size: 10px,
        font-style: normal,
        font-weight: 400,
        text-align: left,
        line-height: normal,
        letter-spacing: 0.45px,
        text-transform: uppercase),

    serif: (font-family: $fontSerif,
        font-size: 14px,
        line-height: 20px,
        font-weight: 400,
        font-style: normal,
        text-transform: none,
    ),

    mobile: (font-family: $font,
        font-size: 11x,
        font-style: normal,
        font-weight: 400,
        line-height: 14px,
        letter-spacing: 0.6px,
        text-transform: uppercase),

    hl1: (font-family: $font,
        color: $blue,
        font-size: 136px,
        font-style: normal,
        font-weight: 350,
        line-height: normal,
        letter-spacing: 0.4,
        text-transform: uppercase),

    hl2: (font-family: $font,
        color: $white,
        font-size: 57px,
        font-style: normal,
        font-weight: 400,
        line-height: 58px,
        letter-spacing: 0.57px,
        text-transform: uppercase),

        hl3: (font-family: $font,
        color: $primaryBlack,
        font-size: 22px,
        font-style: normal,
        font-weight: 400,
        line-height: 23px,
        letter-spacing: 0.22px,
        text-transform: uppercase),

        hl4: (font-family: $font,
        color: $blue,
        font-size: 22px,
        font-style: normal,
        font-weight: 400,
        line-height: 23px,
        letter-spacing: 0.22px,
        text-transform: uppercase),
    )