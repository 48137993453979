header {
    width: 100%;
    position: fixed;
    // height: $headerheightscroll;
    max-width: $fullwidth;
    padding: $spaceTwo $spaceOne $spaceTwo $spaceOne;
    z-index: 1000;
    @include blur-background(10px);
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .header-left {
        display: flex;
        flex-direction: row;
        align-items: top;
        gap: 6px;

        .blazon {
            img {
                height: 28px;
            }
        }
    }

    .nav-mobile {
        display: none;
    }

    nav {
        margin-left: auto;

        .nav-link {
            @include primaryButton(none, $primaryBlack, 0px);
        }

        .nav-link.active svg {
            visibility: visible;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            display: inline-block;
        }
    }

}

@include respond-to('phone') {

    header {
        width: 100%;
        max-width: $fullwidth;
        padding: $spaceTwo $spaceOne $spaceTwo $spaceOne;
        z-index: 2000;
        @include blur-background(5px);
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .nav-mobile {
            display: block;
            cursor: pointer;
        }

        nav {
            display: none;
        }

    }

}