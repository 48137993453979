// AchievementsPage.module.scss
.toggleButton {
  display: flex;
  justify-content: center;
  position: sticky;
  top: $headerheight; // Adjust this value based on your header or navbar height if needed
  z-index: 2; // Ensure it's above other content
  width: 100%; // Ensure it spans the full width
  height: 100%;
  cursor: pointer;

  .activeButtonStyle,
  .inactiveButtonStyle {
    @include blur-background-2(5px);
    @include apply-typography-styles(map-get($typography-styles, default));
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .activeButtonStyle {
    background-color: $blue;
    color: $white;
  }

  .inactiveButtonStyle {
    background-color: none;
    color: $primaryBlack;

    &:hover {
      background-color: $primaryGrey;
      color: $white;
    }
  }

}

.filterContainer {
  position: sticky;
  top: $headerheight; // Adjust this value based on your header or navbar height if needed
  z-index: 2; // Ensure it's above other content
  width: 100%; // Ensure it spans the full width
}

.content {

  .containerAchievements {
    margin-top: $headerheight;

    .noAchievements {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 80vh;

      span {
        height: 100%;
        color: $black50;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }



  .achievementContainer {
    position: relative;
    display: flex;
    flex-direction: row; // Default direction
    height: 550px;
    width: 100%;
    justify-content: center; // Ensure containers are centered

    &.row-reverse {
      flex-direction: row-reverse;
    }

    .circle {
      display: block;
      position: absolute;
      left: 50%;
      top: 124px;
      transform: translate(-50%, -50%);
    }

    .half-circle {
      display: none;
    }
  }

  .imageContainer,
  .detailContainer {
    width: 50%; // Set each container to take up half of the viewport width
    min-height: 100%; // Set the height of the containers

    .achievementImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .detailContainer {
    // height: 550px;
    box-sizing: border-box;
    background-color: $blue;
    color: $white;
    display: flex;
    padding: 50px 40px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .descriptionContainer {
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      p {
        .text {
          @include apply-typography-styles(map-get($typography-styles, serif));
        }
      }

      h2 {
        margin: 0;
      }

      .button {
        @include secondaryButton();
      }
    }
  }

}

@include respond-to('phone') {

  .content {

    .achievementContainer {
      flex-direction: column; // Default direction
      width: 100vw;
      height: auto !important;
      align-items: center; // Adjust this as needed
      justify-content: flex-start;

      &.row-reverse {
        flex-direction: column;
      }

      .circle {
        display: none;
      }

      .half-circle {
        display: block;
        position: absolute;
        left: 5px;
        top: 360px;
        transform: translate(-50%, -50%);
      }
    }

    .imageContainer,
    .detailContainer {
      margin: 0;
      width: 100vw; // Set each container to take up half of the viewport width
    }

    .imageContainer {
      height: 360px;
    }

    .detailContainer {
      text-align: left !important;
      align-items: flex-start !important;
      height: auto; // Set the height of the containers
      box-sizing: border-box;
      background-color: $blue;
      color: $white;
      display: flex;
      padding: 30px $spaceOne 44px $spaceOne;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        margin: 0;
        padding: 0;
      }

      .descriptionContainer {
        padding-top: 34px;
        display: flex;
        gap: 34px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        p {
          padding: 0;
          margin-block-start: 0em;
          margin-block-end: 0em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;


          .text {
            padding: 0;
            hyphens: auto;
            text-align: justify;
            @include apply-typography-styles(map-get($typography-styles, serif));

          }
        }



        .button {
          @include secondaryButton();
          width: 50%;
          box-sizing: border-box;
        }
      }
    }

  }

}