.menu-overlay {
    display: none; // Initially hidden
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(30px);
    z-index: 2005;
    flex-direction: column;
    justify-content: start;
    align-items: top;
    padding-top: $spaceTwo;
    transform: translateY(-100vh); // Start above the screen
    transition: transform 3s ease;

    &.open {
        display: flex;
        transform: translateY(0); // Slide in to cover the screen
    }

    .header-overlay {
        width: $fullwidth;
        max-width: $fullwidth;
        padding: 0 $spaceTwo 0 $spaceOne;
        margin-bottom: 43px;
        display: flex;
        justify-content: space-between;
        align-items: top;

        .header-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            margin-top: -7px;
        }

        .nav-mobile {
            cursor: pointer;
        }
    }

    nav {
        .nav-link {
            @include primaryButtonMobile(none, $primaryBlack, 0px);
        }

        .nav-link.active svg {
            visibility: visible; // Only show SVG for active link
        }

        ul {
            width: 100vw; // This ensures the ul spans the full viewport width
            padding: 0;
            margin-left: calc(-1 * $spaceOne); // Offset any padding that might be on .menu-overlay
            box-sizing: border-box; // Ensures padding doesn't add to the width
            list-style: none;

            li {
                cursor: pointer;
                display: flex;
                width: 100%; // Ensure li elements span the full width of their parent
                padding: 14px $spaceOne 14px $spaceOne;
                border-bottom: 0.5px solid $primaryGrey;
            }
        }
    }
}