/* _mixins.scss */
@import 'variables';

// typography

@font-face {
    font-family: 'ABC Diatype';
    src: url('../assets/fonts/ABCDiatype-Regular.woff2') format('woff2'), 
         url('../assets/fonts/ABCDiatype-Regular.woff') format('woff'); 
    font-weight: normal;
    font-style: normal;
  }

@mixin apply-typography-styles($style) {
    @each $property, $value in $style {
        #{$property}: $value;
    }
}

// effects
@mixin blur-background($blur: 10px) {
    backdrop-filter: blur($blur);
    background-color: $whiteTerciary;
}

@mixin blur-background-1($blur: 10px) {
    backdrop-filter: blur($blur);
    background-color: $whiteQuarternary;
}

@mixin blur-background-2($blur: 10px) {
    backdrop-filter: blur($blur);
    background-color: $black10-70;
}


//buttons
@mixin primaryButton($bg-color, $text-color, $border-stlye) {
    color: $primaryBlack;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex; // Align SVG and text
    align-items: center;
    padding: 0 0 0 25px;

    svg {
        visibility: hidden; // Hide SVG by default
        width: 10.2px;
        height: 8.9px;
        fill: $primaryBlack;
        margin-right: 8px;
    }

    &:hover {
        opacity: 50%;
    }

    &:active {
        opacity: 50%;
    }

    &:focus {
        outline: none;
        opacity: 50%;
    }

}

// NAVIGATION BUTTON DONT CHANGE
@mixin primaryButtonMobile($bg-color, $text-color, $border-stlye) {
    display: flex;
    width: 100%;
    height: 100%;
    color: $primaryBlack;
    text-decoration: none;
    cursor: pointer;
    align-items: center;

    svg {
        visibility: hidden; // Hide SVG by default
        width: 10.2px;
        height: 8.9px;
        fill: $primaryBlack;
        margin-right: 8px;
    }

    &:hover {
        opacity: 50%;
        background-color: none;
    }

    &:active {
        opacity: 50%;
        background-color: none;
    }

    &:focus {
        outline: none;
        opacity: 50%;
    }
}

//ACHIEVEMENT PAGE
@mixin secondaryButton() {
    display: flex;
    padding: 10px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    box-sizing: border-box;
    background-color: $blue;
    color: $white;
    @include apply-typography-styles(map-get($typography-styles, default));
    border: 0.5px solid $white;
    text-decoration: none;

    &:hover {
        background-color: $white;
        color: $blue;
    }

    &.active {
        background-color: $black50;
        color: $white;
    }
}

//NEWS AND ABOUT
@mixin terciaryButton() {
    box-sizing: border-box;
    display: flex;
    padding: 10px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border: 0.5px solid $primaryBlack;
    background-color: none;
    color: $primaryBlack;
    @include apply-typography-styles(map-get($typography-styles, default));
    text-decoration: none;

    &:hover {
        box-sizing: border-box;
        border: 0.5px solid $primaryGrey;
        border: none; 
        background-color: $primaryGrey;
        color: $white;
    }

    &.active {
        border: none; 
        background-color: $black50;
        color: $white;
    }
}

@mixin buttonFilter() {
    background-color: $white;
    @include apply-typography-styles(map-get($typography-styles, default));
    display: inline-flex;
    padding: 5px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: $primaryGrey;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        background-color: $primaryGrey;
        color: $white;
    }

    &.active {
        background-color: $black50;
        color: $white;
    }
}


@mixin filters() {
    @include blur-background-2(10px);
    @include apply-typography-styles(map-get($typography-styles, default));
    min-height: 5px;
    width: 100vw;
    margin: 0;
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: $primaryGrey;
        color: $white;
    }
}

@mixin headline() {
    @include blur-background-2(10px);
    @include apply-typography-styles(map-get($typography-styles, default));
    min-height: 5px;
    width: 100vw;
    margin: 0;
    padding: 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}


//responsiveness

@mixin respond-to($breakpoint) {
    @if $breakpoint =='phone' {
        @media screen and (max-width: 790px) {
            @content;
        }
    }

    @if $breakpoint =='medium' {
        @media screen and (max-width: 950px) {
            @content;
        }
    }

    // You can add more breakpoints here
}